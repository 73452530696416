import { API_GET_AUTH_TYPE } from "consts";
import { useQuery } from "react-query";
import httpClient from "utils/http-client";

const getAuthType = ({ queryKey }) =>
  httpClient
    .get(API_GET_AUTH_TYPE, { params: { userEmail: queryKey[1] } })
    .then((response) => response);
export const useAuthType = (userEmail, config) =>
  useQuery(["user-auth-type", userEmail], getAuthType, { retry: false });
