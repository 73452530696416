import { useQuery } from "react-query";
import httpClient from "utils/http-client";
import { API_ACTIVATE } from "consts";

export default function useGetEmail(token, ...config) {
  return useQuery(
    ["email", token],
    () => httpClient.get(`${API_ACTIVATE}/${token}`),
    ...config
  );
}
