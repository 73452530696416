import { useEffect } from "react";
import { useAuthType } from "queryHooks/auth/useAuthType";
import { useSAMLReqest } from "queryHooks/auth/useSAMLRequest";
import { useDispatch } from "react-redux";
import { showAlert } from "slices/alertsSlice";
import { DEFAULT_REDIRECT_URL } from "consts";
import styles from "./loginContainer.module.scss";
import Loader from "orion/lib/loader";

const SSOLogin = ({ setStep, redirectUrl, notUsingSSO, email }) => {
  const dispatch = useDispatch();

  const { data: authType, isFetched, error } = useAuthType(email);

  const { mutate: samlRequest } = useSAMLReqest();

  const { mspID, isActivated, isSSOEnabled, isFailsafeEnabledForUser } =
    authType || {};

  const isAuthTypeFetched = !error && isFetched;

  const shouldShowPasswordScreen = isSSOEnabled && notUsingSSO;

  useEffect(() => {
    if (error) {
      dispatch(
        showAlert({
          message: error?.message || "Internal server error",
          type: "danger",
        })
      );
      setStep(0);
    } else {
      if (isAuthTypeFetched) {
        if (isActivated) {
          if (isSSOEnabled) {
            if (shouldShowPasswordScreen) {
              if (isFailsafeEnabledForUser) {
                setStep(2); // Show password screen
              } else {
                dispatch(
                  showAlert({
                    message:
                      "Login using credentials not allowed. Please login using Single Sign-on.",
                    type: "danger",
                  })
                );
                setStep(0); // Show Email form
              }
            } else {
              // Commonlogin has whitelisting for domain names. So it expects relative URL in args
              const redirectTo =
                redirectUrl === DEFAULT_REDIRECT_URL ? "/" : redirectUrl;
              samlRequest(
                {
                  request: {
                    method: "OrgLoginUsingSAML",
                    args: [`${mspID}`, "msp", redirectTo],
                  },
                },
                {
                  onSuccess: (apiResponse) => {
                    const { response, error } = apiResponse;
                    const { redirectURL } = response || {};

                    if (error) {
                      dispatch(
                        showAlert({
                          message: error?.message || "Unable to authenticate",
                          type: "danger",
                        })
                      );
                      setStep(0); // Show Email form
                    } else {
                      window.location = redirectURL;
                    }
                  },
                  onError: () => {
                    dispatch(
                      showAlert({
                        message:
                          "Something went wrong, please try again after sometime.",
                        type: "danger",
                      })
                    );
                    setStep(0); // Show Email form
                  },
                }
              );
            }
          } else {
            // If SSO is not enabled show password screen
            setStep(2);
          }
        } else {
          dispatch(
            showAlert({
              message:
                "Your account is inactive. To activate it, please use the activation link sent to you via email.",
              type: "danger",
            })
          );
          setStep(0); // Show email form
        }
      }
    }
  }, [
    dispatch,
    samlRequest,
    redirectUrl,
    setStep,
    isActivated,
    isAuthTypeFetched,
    mspID,
    shouldShowPasswordScreen,
    error,
    isFailsafeEnabledForUser,
    isSSOEnabled,
  ]);

  return (
    <Loader isLoading>
      <div className={styles.login_loader} />
    </Loader>
  );
};

export default SSOLogin;
