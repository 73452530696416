import React, { useState } from "react";
import banner0 from "assets/images/Banner-Gsuite-changes-76-outlined.svg";
import banner1 from "assets/images/Banner-M365-Outlined-76.svg";
import banner2 from "assets/images/Banner-NAS-Outlined-76.svg";
import banner3 from "assets/images/Banner-VMware-Outlined-76.svg";

const hrefs = [
  "https://www.druva.com/products/saas-backup/",
  "https://www.druva.com/solutions/microsoft-365-backup/",
  "https://www.druva.com/solutions/nas-backup/",
  "https://www.druva.com/solutions/vmware-cloud-backup/",
];

const PageFooter = () => {
  const [bannerIndex, setBannerIndex] = useState(Math.floor(Math.random() * 4));
  return (
    <a className="oui-fixed-bottom" href={hrefs[bannerIndex]}>
      <img
        className="oui-w-100 oui-h-auto"
        onClick={() => {
          setBannerIndex((bannerIndex + 1) % 4);
        }}
        src={
          bannerIndex === 0
            ? banner0
            : bannerIndex === 1
            ? banner1
            : bannerIndex === 2
            ? banner2
            : banner3
        }
        alt="banner"
      />
    </a>
  );
};

export default PageFooter;
