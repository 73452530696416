import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Auth from "pages/Auth/Auth";
import PasswordError from "pages/PasswordError/PasswordError";
import AuthFailed from "pages/AuthFailed/AuthFailed";
import SAMLError from "./SAMLError/SamlError";
import {
  ROUTE_AUTH_ERROR,
  ROUTE_AUTH_SAML_ERROR,
  ROUTE_AUTH,
  ROUTE_PASSWORD_ERROR,
} from "consts";

const App = () => (
  <Fragment>
    <Routes>
      <Route index path={ROUTE_AUTH} element={<Auth />} />
      <Route path={ROUTE_PASSWORD_ERROR} element={<PasswordError />} />
      <Route path={ROUTE_AUTH_ERROR} element={<AuthFailed />} />
      <Route path={ROUTE_AUTH_SAML_ERROR} element={<SAMLError />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  </Fragment>
);

export default App;
