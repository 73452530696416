import { useMutation } from "react-query";
import httpClient from "utils/http-client";
import { API_ACTIVATE } from "consts";

export default function useActivate(token, config) {
  return useMutation(
    (data) => httpClient.post(`${API_ACTIVATE}/${token}`, data),
    config
  );
}
