import React, { useMemo, useCallback } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Form, FormGroup, Button } from "orion";

import OTPComponent from "shared/OTPComponent";

import BackButton from "../BackButton";
import styles from "../FormContainer.module.scss";

const VerificationSchemaCreator = (Yup) =>
  Yup.object().shape({
    mfaCode: Yup.string().length(6).required(),
    mfaVerActive: Yup.bool().oneOf([true]),
  });

const BEValidationMessage = "Invalid verification code";

const countDownRenderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    return (
      <span
        className="oui-small oui-text-danger"
        data-testid="login-mfa-expiration-lbl"
      >
        Verification code expired
      </span>
    );
  } else {
    return (
      <>
        <span className="oui-small" data-testid="login-mfa-expiration-lbl">
          The verification code expires in{" "}
        </span>
        <span>
          {minutes}:{zeroPad(seconds)}
        </span>
      </>
    );
  }
};

const VerificationForm = ({
  setStep,
  onSubmit,
  expiredDate,
  onResendEmail,
}) => {
  const initialValues = useMemo(
    () => ({ mfaCode: "", mfaVerActive: true }),
    []
  );

  const onSubmitHandler = useCallback(
    async (values, formik) => {
      try {
        await onSubmit(values);
      } catch (e) {
        //TODO update after initialErrors props will be handled by Form
        formik.setErrors({ mfaCode: BEValidationMessage });
      }
    },
    [onSubmit]
  );
  return (
    <>
      <div className="oui-card-body oui-mx-3">
        <div className="oui-mt-2 oui-mb-2" data-testid="login-mfa-prompt-lbl">
          Enter the verification OTP that we have sent to your registered email
          address
        </div>
        <Form
          validationSchema={VerificationSchemaCreator}
          withValidation
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          className={styles.formContainer}
          renderChildren={(props) => (
            <>
              <FormGroup
                className={`${styles.input} oui-mb-1`}
                dataTestId="login-mfa-code-form"
              >
                <OTPComponent
                  OTPLength={6}
                  value={props.values.mfaCode}
                  inputClassName={`oui-form-control oui-font-weight-bold oui-mr-2 ${
                    props.errors.mfaCode === BEValidationMessage
                      ? "oui-is-invalid"
                      : ""
                  }`}
                  onChange={(mfaCode) =>
                    props.setFieldValue("mfaCode", mfaCode)
                  }
                  disabled={!props.values.mfaVerActive}
                  errorMsg={
                    props.errors.mfaCode === BEValidationMessage
                      ? BEValidationMessage
                      : null
                  }
                />
              </FormGroup>
              <Button
                label="Verify"
                dataTestId="login-mfa-verify-btn"
                size="lg"
                block
                type="primary"
                onClick={props.submitForm}
                className="oui-mb-2"
                disabled={!props.isValid || props.values.mfaCode?.length !== 6}
              />
              <input className="oui-d-none" type="submit" />

              <div className="oui-mb-3">
                <Countdown
                  date={expiredDate}
                  renderer={countDownRenderer}
                  onComplete={() => {
                    props.setFieldValue("mfaVerActive", false);
                  }}
                />
                {props.values.mfaVerActive && (
                  <Button
                    type="link"
                    label="Resend Email"
                    onClick={
                      props.values.mfaVerActive
                        ? onResendEmail
                        : () => {
                            setStep(2);
                          }
                    }
                    size="sm"
                    className="oui-text-decoration-none oui-ml-2"
                    dataTestId="login-mfa-resend-btn"
                  />
                )}
              </div>
            </>
          )}
        />
        <div className="oui-text-center">
          <BackButton
            onClick={() => {
              setStep(2);
            }}
            label="Back"
            dataTestId="login-mfa-back-btn"
          />
        </div>
      </div>
      <div className="oui-card-footer oui-text-center oui-p-1">
        <a
          href="https://help.druva.com/en/articles/8580834-troubleshooting-and-faqs"
          target="_blank"
          className="oui-text-decoration-none"
          data-testid="login-mfa-no-email-btn"
          rel="noreferrer"
        >
          Did not receive email?
        </a>
      </div>
    </>
  );
};

export default VerificationForm;
