import React from "react";
import { useSelector } from "react-redux";
import { Loader } from "orion";
import { selectShow } from "slices/fullPageLoaderSlice";

const FullPageLoader = () => {
  const show = useSelector(selectShow);
  return <Loader isLoading={show} fullPage />;
};

export default FullPageLoader;
