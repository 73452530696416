import { createSlice } from "@reduxjs/toolkit";

const fullPageLoaderSlice = createSlice({
  name: "fullPageLoader",
  initialState: {
    show: false,
  },
  reducers: {
    showLoader(state) {
      state.show = true;
    },
    hideLoader(state) {
      state.show = false;
    },
    toggleLoader(state) {
      state.show = !state.show;
    },
  },
});

export const {
  toggleLoader,
  showLoader,
  hideLoader,
} = fullPageLoaderSlice.actions;

export const selectShow = (state) => state.fullPageLoader.show;

export default fullPageLoaderSlice.reducer;
