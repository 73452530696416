import React, { useMemo, useRef, useEffect } from "react";
import { Heading, Input, Form, FormGroup, Button } from "orion";

import BackButton from "../BackButton";
import styles from "../FormContainer.module.scss";

const PasswordSchemaCreator = (Yup) =>
  Yup.object().shape({
    password: Yup.string().required("Required"),
  });

const PasswordForm = ({ setStep, initialValue, onSubmit, email }) => {
  const initialValues = useMemo(() => ({ password: initialValue }), [
    initialValue,
  ]);
  const passwordInputRef = useRef();

  useEffect(() => {
    passwordInputRef.current.focus();
  }, []);
  return (
    <>
      <div className="oui-card-body oui-mx-3">
        <Heading
          type="4"
          text="Login to your account"
          className="oui-font-weight-normal oui-mt-2 oui-mb-4 oui-text-center"
          dataTestId="login-pw-lbl"
        />
        <Form
          validationSchema={PasswordSchemaCreator}
          withValidation
          initialValues={initialValues}
          onSubmit={onSubmit}
          className={styles.formContainer}
          renderChildren={(props) => (
            <>
              <div className="oui-mb-4 oui-pt-2 oui-ml-2">{email}</div>
              <FormGroup className={`${styles.input} oui-mb-2`}>
                <Input
                  data-testid="login-pw-passwd-txt"
                  innerRef={passwordInputRef}
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  validationProps={props}
                  isInvalid={
                    props.touched.password &&
                    props.errors.password !== undefined
                  }
                  autoComplete="off"
                />
              </FormGroup>
              <Button
                label="Submit"
                dataTestId="login-pw-submit-btn"
                size="lg"
                block
                type="primary"
                onClick={props.submitForm}
                className="oui-mb-4"
                disabled={!props.isValid}
              />
            </>
          )}
        />
        <div className="oui-text-center">
          <BackButton
            dataTestId="login-pw-back-btn"
            onClick={() => {
              setStep(0);
            }}
            label="Back"
          />
        </div>
      </div>
    </>
  );
};

export default PasswordForm;
