import { useMutation, useQueryClient } from "react-query";
import httpClient from "utils/http-client";
import { API_LOG_IN } from "consts";

export default function useLogIn(config) {
  const queryClient = useQueryClient();
  return useMutation((data) => httpClient.post(API_LOG_IN, data), {
    ...config,
    onSettled: (data, error, ...args) => {
      queryClient.setQueryData(["login"], { data, error });
      config?.onSettled?.(data, error, ...args);
    },
  });
}
