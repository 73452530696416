export const API_URL = window._env_.APP_API;
export const DEFAULT_REDIRECT_URL = window._env_.APP_DEFAULT_REDIRECT_URL;
export const BASENAME = window._env_.APP_BASENAME;
export const IS_TEST = process.env.NODE_ENV === "test";
export const DOMAIN = window._env_.APP_DOMAIN;
export const API_COMMONLOGIN_URL = window._env_.APP_COMMONLOGIN_URL;

export const API_REDIRECT = `${API_URL}/login/step3`;
export const API_VERIFY = `${API_URL}/login/step2`;
export const API_LOG_IN = `${API_URL}/login/step1`;
export const API_RESEND_EMAIL = `${API_URL}/login/resend-mfa`;
export const API_ACTIVATE = `${API_URL}/user/activation`;
export const API_RESET_PASSWORD = `${API_URL}/resetpwd/:token`;
export const API_GET_AUTH_TYPE = `${API_URL}/users/authinfo`;
export const API_LOGIN_USING_SAML = `${API_COMMONLOGIN_URL}/login`;
export const API_STASHED_RESPONSE = `${API_URL}/token`;

export const ROUTE_AUTH = "/auth/:version";
export const ROUTE_PASSWORD_ERROR = "/password-error";
export const ROUTE_AUTH_ERROR = "/auth-failed";
export const ROUTE_AUTH_SAML_ERROR = "/auth-saml-error";
export const GENERIC_ERROR_MESSAGE = "Unexpected error occured";
