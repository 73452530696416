import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";

import { DEFAULT_REDIRECT_URL } from "consts";

import LoginContainer from "./login/LoginContainer";
import ActivationContainer from "./activation/ActivationContainer";
import ResetPasswordContainer from "./resetpwd/ResetPasswordContainer";
import SamlConsume from "pages/SamlConsume/SamlConsume";

const FormContainer = ({ version }) => {
  const location = useLocation();
  const { token, redirectUrl = DEFAULT_REDIRECT_URL } = useMemo(() => {
    return parse(location.search);
  }, [location.search]);

  // eslint-disable-next-line default-case
  switch (version) {
    case "login":
      return <LoginContainer redirectUrl={redirectUrl} />;
    case "activate":
      return <ActivationContainer token={token} redirectUrl={redirectUrl} />;
    case "resetpwd":
      return <ResetPasswordContainer token={token} />;
    case "samlconsume":
      return <SamlConsume />;
  }
};

FormContainer.defaultProps = {
  version: "login",
};

FormContainer.propTypes = {
  version: PropTypes.oneOf([
    "login",
    "verify",
    "activate",
    "change",
    "locked",
    "resetpwd",
    "samlconsume",
  ]).isRequired,
};

export default FormContainer;
