import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { Loader } from "orion";
// import { Cookies } from "react-cookie";

import { DEFAULT_REDIRECT_URL, GENERIC_ERROR_MESSAGE } from "consts";
import { showAlert } from "slices/alertsSlice";
import useLogIn from "queryHooks/auth/useLogIn";
import useResendEmail from "queryHooks/auth/useResendEmail";
import useVerify from "queryHooks/auth/useVerify";

import AccountLocked from "./AccountLocked";
import EmailForm from "./EmailForm";
import PasswordForm from "./PasswordForm";
import VerificationForm from "./VerificationForm";
import { useRedirect } from "queryHooks/auth/useRedirectUrl";
import SSOLogin from "./SSOLogin";
import { setSessionCookies } from "utils/cookies";
import styles from "./loginContainer.module.scss";

// const cookies = new Cookies();
const LoginContainer = ({ redirectUrl }) => {
  const dispatch = useDispatch();
  const { mutate: logIn, isLoading: loginInIsLoading } = useLogIn();
  const { mutateAsync: verify, isLoading: verifyIsLoading } = useVerify();
  const { mutate: resendEmail, isLoading: resendEmailIsLoading } =
    useResendEmail();
  // const { mutateAsync: getRedirect, isLoading: getRedirectUrlIsLoading } =
  //   useRedirectUrl();
  const { redirect, redirecting: getRedirectUrlIsLoading } = useRedirect();

  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notUsingSSO, setNotUsingSSO] = useState(false);
  const [mfaVerificationToken, setMfaVerificationToken] = useState("");
  const [expiredDate, setExpiredDate] = useState();
  const [unlockMoment, setUnlockMoment] = useState();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const submitEmailFormHandler = useCallback(({ email, notUsingSSO }) => {
    setEmail(email);
    setNotUsingSSO(notUsingSSO);
    setStep(1);
  }, []);

  const submitPasswordFormHandler = useCallback(
    ({ password }) => {
      logIn(
        { email, password },
        {
          onSuccess: (result) => {
            ReactDOM.unstable_batchedUpdates(() => {
              setPassword(password);
              setMfaVerificationToken(result.mfaVerificationToken);
              setStep(3);
              setExpiredDate(Date.now() + 300000); //5 min
            });
          },
          onError: (e) => {
            if (e.message === "account locked") {
              ReactDOM.unstable_batchedUpdates(() => {
                setUnlockMoment(e.unlockMoment);
                setStep(-1);
              });
            } else {
              dispatch(
                showAlert({
                  message: e.message || GENERIC_ERROR_MESSAGE,
                  type: "danger",
                })
              );
            }
          },
        }
      );
    },
    [dispatch, email, logIn]
  );

  const submitVerificationFormHandler = useCallback(
    async ({ mfaCode }) => {
      try {
        setIsRedirecting(true);
        const { accessToken, refreshToken } = await verify({
          mfaVerificationToken,
          mfaCode,
        });
        setSessionCookies(refreshToken, accessToken);
        redirect(accessToken, redirectUrl);
      } catch (e) {
        setIsRedirecting(false);
        if (e.code === "GoAuth-1041") {
          ReactDOM.unstable_batchedUpdates(() => {
            //setUnlockMoment(e.unlockMoment);
            setStep(-1);
          });
        } else {
          dispatch(
            showAlert({
              message: e.message || GENERIC_ERROR_MESSAGE,
              type: "danger",
            })
          );
        }
      }
    },
    [dispatch, mfaVerificationToken, redirect, redirectUrl, verify]
  );

  const resendEmailHandler = useCallback(() => {
    resendEmail(
      { password, mfaVerificationToken },
      {
        onSuccess: (result) => {
          setMfaVerificationToken(result.mfaVerificationToken);
          setExpiredDate(Date.now() + 300000); //5 min
          dispatch(
            showAlert({
              message: "Email successfully sent",
              type: "success",
            })
          );
        },
        onError: (e) => {
          dispatch(
            showAlert({
              message: e.message || GENERIC_ERROR_MESSAGE,
              type: "danger",
            })
          );
        },
      }
    );
  }, [dispatch, mfaVerificationToken, password, resendEmail]);

  if (
    loginInIsLoading ||
    verifyIsLoading ||
    getRedirectUrlIsLoading ||
    resendEmailIsLoading ||
    isRedirecting
  ) {
    return (
      <div
        className="oui-card-body oui-d-flex oui-align-items-center oui-justify-content-center"
        data-testid="loader-wrapper"
      >
        <Loader isLoading>
          <div className={styles.login_loader} />
        </Loader>
      </div>
    );
  }

  if (step === 0) {
    return (
      <EmailForm
        isInitialValid={!!email}
        onSubmit={submitEmailFormHandler}
        initialValue={email}
      />
    );
  }
  if (step === 1) {
    return (
      <SSOLogin
        setStep={setStep}
        redirectUrl={redirectUrl}
        email={email}
        notUsingSSO={notUsingSSO}
      />
    );
  }
  if (step === 2) {
    return (
      <PasswordForm
        setStep={setStep}
        onSubmit={submitPasswordFormHandler}
        initialValue={""}
        email={email}
      />
    );
  }
  if (step === 3) {
    return (
      <VerificationForm
        setStep={setStep}
        onSubmit={submitVerificationFormHandler}
        onResendEmail={resendEmailHandler}
        expiredDate={expiredDate}
      />
    );
  }
  if (step === -1) {
    return <AccountLocked unlockMoment={unlockMoment} />;
  }
};

LoginContainer.defaultProps = {
  redirectUrl: DEFAULT_REDIRECT_URL,
};

export default LoginContainer;
