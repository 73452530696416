import React from "react";
import { Icon } from "orion";
import backIcon from "assets/icons/back.svg";
import styles from "./BackButton.module.scss";

const BackButton = ({ onClick, label = "Back", dataTestId }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${styles.backButton} oui-btn oui-btn-link oui-btn-sm oui-text-decoration-none oui-mb-2`}
      data-testid={dataTestId}
    >
      <Icon src={backIcon} className={`${styles.backIcon} oui-mr-2`} />
      {label}
    </button>
  );
};

export default BackButton;
