import React, { useMemo } from "react";

import styles from "./AuthFooter.module.scss";

const AuthFooter = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  return (
    <div className="oui-small oui-mt-3">
      <span className={styles.footerPart}>
        Druva Technologies Pte. Ltd. © {currentYear}.
      </span>
      <span className={styles.footerPart}> | </span>
      <a
        className={`${styles.footerPart} oui-text-decoration-none`}
        type="link"
        href="https://www.druva.com/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      <span className={styles.footerPart}> | </span>
      <a
        className={`${styles.footerPart} oui-text-decoration-none`}
        type="link"
        href="https://support.druva.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Support
      </a>
    </div>
  );
};

export default AuthFooter;
