import { createSlice } from "@reduxjs/toolkit";

const samlAuth = createSlice({
  name: "alerts",
  initialState: {
    samlError: null,
  },
  reducers: {
    setSSMLError(state, { payload }) {
      state.samlError = payload;
    },
    resetSAMLError(state, { payload }) {
      state.samlError = null;
    },
  },
});

export const { setSSMLError, resetSAMLError } = samlAuth.actions;

export const samlError = (state) => state.samlAuth.samlError;

export default samlAuth.reducer;
