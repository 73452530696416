import { Icon } from "orion";
import React, { useMemo } from "react";
import * as Yup from "yup";

import successGreenSVG from "assets/icons/success.svg";
import successGraySVG from "assets/icons/success_1.svg";

export const passwordSchema = Yup.string()
  .required("Required")
  .min(12, "tooShort")
  .max(64, "Maximum 64 characters")
  .test("lowerCaseRequired", "lowerCaseRequired", function (value) {
    const pattern = /[a-z]/;
    return pattern.test(value);
  })
  .test("upperCaseRequired", "upperCaseRequired", function (value) {
    const pattern = /[A-Z]/;
    return pattern.test(value);
  })
  .test("numberRequired", "numberRequired", function (value) {
    const pattern = /\d/;
    return pattern.test(value);
  })
  .test(
    "specialCharacterRequired",
    "specialCharacterRequired",
    function (value) {
      const pattern = /[^A-Za-z\d]/;
      return pattern.test(value);
    }
  );

const PasswordTooltipRow = ({ error, message }) => {
  return (
    <div
      className={`oui-d-flex oui-align-items-center oui-mb-1 ${
        error ? "" : "oui-text-success"
      }`}
    >
      <Icon
        className="oui-mr-2"
        src={error ? successGraySVG : successGreenSVG}
        muted={false}
      />
      {message}
    </div>
  );
};

const PasswordTooltip = ({ password }) => {
  const errors = useMemo(() => {
    try {
      passwordSchema.validateSync(password, { abortEarly: false });
      return [];
    } catch (e) {
      return e.errors;
    }
  }, [password]);

  return (
    <div className="oui-ml-1" data-testid="activ-acc-pw-hint-lbl">
      <PasswordTooltipRow
        error={errors.includes("tooShort")}
        message="12 characters"
      />
      <PasswordTooltipRow
        error={
          errors.includes("lowerCaseRequired") ||
          errors.includes("upperCaseRequired")
        }
        message="One upper case and one lower case letter [a-z, A-Z]"
      />
      <PasswordTooltipRow
        error={errors.includes("numberRequired")}
        message="One number [0-9]"
      />
      <PasswordTooltipRow
        error={errors.includes("specialCharacterRequired")}
        message="One special character [e.g. #@*&%]"
      />
    </div>
  );
};

export default PasswordTooltip;
