import React from "react";
import { Page, Container, Row, Col } from "orion";
import { useParams } from "react-router-dom";

import ImgLogo from "../../assets/images/druva-logo-new.svg";

import styles from "./Auth.module.scss";
import FormContainer from "./components/FormContainer";
import AuthFooter from "./AuthFooter";
import PageFooter from "./PageFooter";

const Auth = () => {
  const { version } = useParams(); //login/otplogin/activate/change/locked/samlconsume
  return (
    <Page honourNavHeight={false} className={styles.page}>
      <Page.Main>
        <Page.Body>
          <Container className="d-flex flex-column h-100 justify-content-center align-items-center">
            <Row>
              <Col>
                <div className={`${styles.card} oui-card`}>
                  <div className="oui-my-4 oui-mx-auto">
                    <img
                      src={ImgLogo}
                      alt="Druva Logo"
                      className="oui-mt-2"
                      data-testid="login-mfa-logo"
                    />
                  </div>
                  <FormContainer version={version} />
                </div>
              </Col>
            </Row>
            <AuthFooter />
          </Container>
          <PageFooter />
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default Auth;
