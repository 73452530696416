import { useCallback } from "react";
import { useMutation } from "react-query";
import httpClient from "utils/http-client";
import { API_REDIRECT } from "consts";

export default function useRedirectUrl(config) {
  return useMutation(
    ({ accessToken, redirectUrl }) =>
      httpClient.post(
        API_REDIRECT,
        {
          redirectUrl,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      ),
    config
  );
}

export const useRedirect = () => {
  const { mutateAsync: getRedirect, isLoading: getRedirectUrlIsLoading } =
    useRedirectUrl();

  const redirect = useCallback(
    async (accessToken, redirectUrl) => {
      const { redirectUrl: verifiedRedirectUrl } = await getRedirect({
        accessToken,
        redirectUrl,
      });
      window.location = verifiedRedirectUrl;
    },
    [getRedirect]
  );

  return {
    redirect,
    redirecting: getRedirectUrlIsLoading,
  };
};
