import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "orion";
import { useDispatch } from "react-redux";

import { showAlert } from "slices/alertsSlice";
import useResetPassword from "queryHooks/auth/useResetPassword";
import useEmailForResetPassword from "queryHooks/auth/useEmailForResetPassword";
import { GENERIC_ERROR_MESSAGE } from "consts";

import ActivateForm from "../activation/ActivateForm";

const ResetPasswordContainer = ({ token }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutateAsync: resetPassword, isLoading: resetPasswordInProgress } =
    useResetPassword(token);

  const { data: emailData, isLoading: emailIsLoading } =
    useEmailForResetPassword(token, {
      onError: (e) => {
        if (e.code === "GoAuth-1003") {
          navigate("/password-error");
        } else {
          dispatch(
            showAlert({
              message: e.message || GENERIC_ERROR_MESSAGE,
              type: "danger",
            })
          );
        }
      },
    });

  const resetPasswordHandler = useCallback(
    async ({ password, repeatedPassword }) => {
      try {
        await resetPassword({ password, repeatedPassword, token });
        dispatch(
          showAlert({
            message: "Reset password successful.",
            type: "success",
          })
        );
        navigate("/auth/login", { replace: true });
      } catch (e) {
        dispatch(
          showAlert({
            message: e.message || GENERIC_ERROR_MESSAGE,
            type: "danger",
          })
        );
      }
    },
    [dispatch, navigate, resetPassword, token]
  );
  if (resetPasswordInProgress || emailIsLoading) {
    return (
      <div
        className="oui-card-body oui-d-flex oui-align-items-center oui-justify-content-center"
        data-testid="activation-loader-wrapper"
      >
        <Loader isLoading />
      </div>
    );
  }

  return emailData ? (
    <ActivateForm
      isResetPassword
      email={emailData.email}
      onSubmit={resetPasswordHandler}
    />
  ) : null;
};

export default ResetPasswordContainer;
