import React from "react";
import { Page, Container, Heading } from "orion";

import blocked from "assets/images/blocked.svg";
import Navbar from "shared/Navbar";

const PasswordError = () => {
  return (
    <>
      <Navbar brandName="Druva" />
      <Page>
        <Page.Main>
          <Page.Body>
            <Container className="d-flex flex-column h-100 justify-content-center align-items-center">
              <img
                src={blocked}
                alt="password error"
                style={{ height: "104px" }}
                className="oui-mb-4"
                data-testid="login-errors-ico"
              />
              <Heading
                type="5"
                text="Password Error"
                dataTestId="login-errors-lbl"
              />
              <div
                className="oui-text-center oui-text-secondary"
                data-testid="login-errors-msg-lbl"
              >
                <div>This password link is either expired or invalid.</div>
                <div>Contact your Druva administrator for a new one.</div>
              </div>
            </Container>
          </Page.Body>
        </Page.Main>
      </Page>
    </>
  );
};

export default PasswordError;
