import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { Page, Container, Heading, Input, Button } from "orion";

import authFailed from "assets/images/auth-failed.svg";
import Navbar from "shared/Navbar";
import BackButton from "pages/Auth/components/BackButton";

const AuthFailed = () => {
  const queryClient = useQueryClient();
  const loginData = queryClient.getQueryData("login");
  const navigate = useNavigate();
  const backToLoginHandler = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return (
    <>
      <Navbar brandName="Druva" />
      <Page>
        <Page.Main>
          <Page.Body>
            <Container className="h-100 d-flex justify-content-center align-items-center">
              <div
                className="oui-d-flex oui-flex-column oui-justify-content-center oui-align-items-center"
                style={{ maxWidth: "432px" }}
              >
                <img
                  src={authFailed}
                  alt="authentication failed"
                  style={{ height: "104px" }}
                  className="oui-mb-4"
                  data-testid="login-auth-fail-ico"
                />
                <Heading
                  type="5"
                  text="Authentication Failed"
                  dataTestId="login-auth-fail-lbl"
                />
                <div
                  className="oui-text-center oui-text-secondary oui-mb-4"
                  data-testid="login-auth-fail-msg-lbl"
                >
                  <div>
                    We apologise for the inconvenience and appreciate your
                    patience.
                  </div>
                  <div>
                    To help us fix this, please contact us with the response at{" "}
                    <a href="/#">Support</a>.
                  </div>
                </div>
                <Input
                  className="oui-mt-2 oui-mb-3"
                  type="textarea"
                  value={loginData?.error?.message}
                  disabled
                  data-testid="login-auth-fail-message-lbl"
                />
                <div className="oui-d-flex oui-w-100 oui-justify-content-between">
                  <BackButton
                    label="Back to Login"
                    onClick={backToLoginHandler}
                    dataTestId="login-auth-fail-back-btn"
                  />
                  <CopyToClipboard text={loginData?.error?.message}>
                    <Button
                      label="Copy to Clipboard"
                      type="primary"
                      size="sm"
                      dataTestId="login-auth-fail-copy-btn"
                    />
                  </CopyToClipboard>
                </div>
              </div>
            </Container>
          </Page.Body>
        </Page.Main>
      </Page>
    </>
  );
};

export default AuthFailed;
