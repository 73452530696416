import { useMutation } from "react-query";

import httpClient from "utils/http-client";
import withUrlParams from "utils/withUrlParams";
import { API_RESET_PASSWORD } from "consts";

export default function useActivate(token, config) {
  return useMutation(
    (data) =>
      httpClient.post(withUrlParams(API_RESET_PASSWORD, { token }), data),
    config
  );
}
