import React, { useMemo } from "react";
import { Heading, Input, Form, FormGroup, Popover, Button } from "orion";

import styles from "../FormContainer.module.scss";
import PasswordTooltip, { passwordSchema } from "./PasswordTooltip";

const ActivateSchemaCreator = (Yup) =>
  Yup.object().shape({
    password: passwordSchema,
    repeatedPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password"), null], "Password don't match"),
  });

const ActivateForm = ({ onSubmit, email, isResetPassword }) => {
  const initialValues = useMemo(
    () => ({ password: "", repeatedPassword: "" }),
    []
  );
  // const passwordInputRef = useRef();

  // useEffect(() => {
  //   passwordInputRef.current.focus();
  // }, []);
  return (
    <>
      <div className="oui-card-body oui-mx-3">
        <Heading
          type="4"
          text={isResetPassword ? "Reset Password" : "Activate your account"}
          className="oui-mt-2 oui-mb-4 oui-text-center"
          dataTestId="activ-acc-title-lbl"
        />
        <Form
          validationSchema={ActivateSchemaCreator}
          withValidation
          initialValues={initialValues}
          onSubmit={onSubmit}
          className={styles.formContainer}
          renderChildren={(props) => (
            <>
              <div
                className="oui-mb-4 oui-pt-2 oui-ml-2"
                data-testid="activ-acc-email-lbl"
              >
                {email}
              </div>
              <FormGroup className={`${styles.input} oui-mb-2`}>
                <Popover
                  type="uncontrolled"
                  title="Password must have at least"
                  placement="right"
                  className={styles.popover}
                  body={() => (
                    <PasswordTooltip password={props.values.password} />
                  )}
                >
                  <Input
                    data-testid="activ-acc-pw-txt"
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                    onFocus={() => {
                      props.setStatus({ focused: { password: true } });
                    }}
                    //innerRef={passwordInputRef}
                    validationProps={props}
                    isInvalid={
                      (props.touched.password &&
                        props.errors.password === "Required") ||
                      props.errors.password === "Maximum 64 characters"
                    }
                    autoComplete="off"
                  />
                </Popover>
                {props.status?.focused.password &&
                  (props.errors.password === undefined || //show hint if no validation message visible below input
                    !props.touched.password || //show hint if before validation (but after focusing)
                    !(
                      props.errors.password === "Required" ||
                      props.errors.password === "Maximum 64 characters"
                    )) && ( //show hint if validation message visible only in tooltip
                    <span className="oui-small oui-text-secondary">
                      Maximum 64 characters
                    </span>
                  )}
              </FormGroup>
              <FormGroup className={`${styles.input} oui-mb-2`}>
                <Input
                  type="password"
                  name="repeatedPassword"
                  placeholder="Confirm Password"
                  validationProps={props}
                  isInvalid={
                    props.touched.repeatedPassword &&
                    props.errors.repeatedPassword !== undefined
                  }
                  data-testid="activ-acc-pw-confirm-txt"
                  autoComplete="off"
                />
              </FormGroup>
              <input className="oui-d-none" type="submit" />
              <Button
                label="Activate"
                dataTestId="activ-acc-activate-btn"
                size="lg"
                block
                type="primary"
                onClick={props.submitForm}
                className="oui-mb-4"
                disabled={!props.isValid || !props.values.password}
              />
            </>
          )}
        />
      </div>
    </>
  );
};

ActivateForm.defaultProps = {
  isResetPassword: false,
};

export default ActivateForm;
