import React from "react";
import PropTypes from "prop-types";
import { Headerbar } from "orion";

const Navbar = ({ brandName }) => {
  return (
    <Headerbar
      brand={{
        name: brandName,
        onClick: () => {},
      }}
    />
  );
};

Navbar.propTypes = {
  brandName: PropTypes.string,
};

export default Navbar;
