import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "orion";
import { useDispatch } from "react-redux";

import { showAlert } from "slices/alertsSlice";
import useActivate from "queryHooks/auth/useActivate";
import useGetEmail from "queryHooks/auth/useGetEmail";
import { GENERIC_ERROR_MESSAGE } from "consts";

import ActivateForm from "./ActivateForm";

const ActivationContainer = ({ token }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutateAsync: activate, isLoading: activateIsLoading } =
    useActivate(token);

  const { data: emailData, isLoading: emailIsLoading } = useGetEmail(token, {
    onError: (e) => {
      if (e.code === "GoAuth-1003") {
        navigate("/password-error");
      } else {
        dispatch(
          showAlert({
            message: e.message || GENERIC_ERROR_MESSAGE,
            type: "danger",
          })
        );
      }
    },
  });

  const activateHandler = useCallback(
    async ({ password, repeatedPassword }) => {
      try {
        await activate({ password, repeatedPassword, token });
        dispatch(
          showAlert({
            message: "Activation successful.",
            type: "success",
          })
        );
        navigate("/auth/login", { replace: true });
      } catch (e) {
        dispatch(
          showAlert({
            message: e.message || GENERIC_ERROR_MESSAGE,
            type: "danger",
          })
        );
      }
    },
    [activate, dispatch, navigate, token]
  );
  if (activateIsLoading || emailIsLoading) {
    return (
      <div
        className="oui-card-body oui-d-flex oui-align-items-center oui-justify-content-center"
        data-testid="activation-loader-wrapper"
      >
        <Loader isLoading />
      </div>
    );
  }

  return emailData ? (
    <ActivateForm email={emailData.email} onSubmit={activateHandler} />
  ) : null;
};

export default ActivationContainer;
