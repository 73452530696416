import { useEffect } from "react";
import { Loader } from "orion";
import { useRedirect } from "queryHooks/auth/useRedirectUrl";
import { useStashedResponse } from "queryHooks/auth/useStashResponse";
import { setSessionCookies } from "utils/cookies";
import styles from "./samlConsume.module.scss";
import { useSearchParams, useNavigate } from "react-router-dom";
import { DEFAULT_REDIRECT_URL, ROUTE_AUTH_SAML_ERROR } from "consts";
import { useDispatch } from "react-redux";
import { showAlert } from "slices/alertsSlice";
import { setSSMLError } from "slices/samlAuth";

const SamlConsume = () => {
  const [params] = useSearchParams();
  const otp = params.get("otp");
  const { data: response, error } = useStashedResponse(otp);
  const { redirect } = useRedirect();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const {
      access_token: accessToken,
      refresh_token: refreshToken,
      relay_state: redirectUrl,
    } = response || {};

    const { code, message } = error || {};

    if (refreshToken && accessToken) {
      const mspRedirectURL =
        redirectUrl && `${redirectUrl}`.includes("msp:")
          ? /^msp:(.*)/gm.exec(redirectUrl)[1]
          : DEFAULT_REDIRECT_URL;
      setSessionCookies(refreshToken, accessToken);
      redirect(accessToken, mspRedirectURL);
    }

    if (code) {
      if (code === "GoAuth-50064") {
        dispatch(setSSMLError(message));
        navigate(ROUTE_AUTH_SAML_ERROR);
      } else {
        dispatch(
          showAlert({
            message,
            type: "danger",
          })
        );
      }
    }
  }, [dispatch, error, navigate, otp, redirect, response]);

  return (
    <Loader isLoading>
      <div className={styles.saml_consume_loader} />
    </Loader>
  );
};

export default SamlConsume;
