import { useQuery } from "react-query";

import httpClient from "utils/http-client";
import { API_RESET_PASSWORD } from "consts";
import withUrlParams from "utils/withUrlParams";

export default function useEmailForResetPassword(token, ...config) {
  return useQuery(
    ["emailForResetPassword", token],
    () => httpClient.get(withUrlParams(API_RESET_PASSWORD, { token })),
    ...config
  );
}
