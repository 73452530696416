import axios from "axios";

const httpClient = axios.create();

// if (process.env.NODE_ENV === "development") {
//   const { worker } = require("mocks/worker");
//   worker.start();
// }

httpClient.interceptors.response.use(
  (res) => res.data,
  (error) => {
    throw error?.response?.data || error;
  }
);
export default httpClient;
