import React, { useEffect, useMemo, useRef } from "react";
import { Heading, Input, Button, Form, FormGroup } from "orion";
import styles from "../FormContainer.module.scss";

const EmailSchemaCreator = (Yup) =>
  Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email address.")
      .required("Enter an email address."),
  });

const EmailForm = ({ isInitialValid, initialValue, onSubmit }) => {
  const initialValues = useMemo(
    () => ({ email: initialValue, notUsingSSO: false }),
    [initialValue]
  );

  const emailInputRef = useRef();

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);
  return (
    <>
      <div className="oui-card-body oui-mx-3">
        <Heading
          type="4"
          text="Login to your account"
          className="oui-font-weight-normal oui-mt-2 oui-mb-4 oui-text-center"
          dataTestId="login-name-prompt"
        />
        <Form
          isInitialValid={isInitialValid}
          validationSchema={EmailSchemaCreator}
          withValidation
          initialValues={initialValues}
          onSubmit={onSubmit}
          className={styles.formContainer}
          renderChildren={(props) => (
            <>
              <FormGroup className={`${styles.input} oui-mb-2 oui-pt-2`}>
                <Input
                  innerRef={emailInputRef}
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  validationProps={props}
                  isInvalid={
                    props.touched.email && props.errors.email !== undefined
                  }
                  data-testid="login-name-username"
                  autoComplete="off"
                />
                <Input
                  type="checkbox"
                  name="notUsingSSO"
                  validationProps={props}
                  data-testid="login-not-using-sso"
                  className="oui-d-none"
                />
              </FormGroup>
              <button
                type="submit"
                data-testid="login-name-next"
                className={[
                  "login-button",
                  "oui-btn",
                  "oui-btn-lg",
                  "oui-btn-primary",
                  "oui-btn-block",
                  "oui-mb-4",
                  ...(!props.isValid ? ["oui-disabled"] : []),
                ].join(" ")}
                onClick={() => {
                  props.setFieldValue("notUsingSSO", false);
                  props.submitForm();
                }}
              >
                Next
              </button>
              <div className="oui-text-center oui-mb-4">
                <button
                  className="oui-btn oui-btn-link oui-btn-sm oui-text-decoration-none oui-mb-2"
                  data-testid="login-name-sso-query-btn"
                  onClick={() => {
                    props.setFieldValue("notUsingSSO", true);
                    props.submitForm();
                  }}
                >
                  Not using Single Sign-On ?
                </button>
              </div>
            </>
          )}
        />
      </div>
      <div className="oui-card-footer"></div>
    </>
  );
};

export default EmailForm;
