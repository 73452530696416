import { DOMAIN } from "consts";
import Cookies from "universal-cookie";

export const setSessionCookies = (refreshToken, accessToken) => {
  const cookies = new Cookies();
  cookies.set("accessToken", accessToken, {
    path: "/",
    domain: DOMAIN,
    secure: true,
    sameSite: "strict",
  });
  cookies.set("refreshToken", refreshToken, {
    path: "/",
    domain: DOMAIN,
    secure: true,
    sameSite: "strict",
  });
};
