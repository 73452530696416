import Page from "orion/lib/page";
import Input from "orion/lib/input";
import Button from "orion/lib/button";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SamlErrorSvg } from "assets/images/saml-error.svg";
import { ReactComponent as BackSvg } from "assets/icons/back.svg";

import "./style.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { showAlert } from "slices/alertsSlice";
import { samlError } from "slices/samlAuth";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const SAMLError = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const samlAuthError = useSelector(samlError);

  return (
    <Page.Main>
      <Page.Body className="oui-d-flex oui-align-items-center oui-justify-content-center oui-flex-column">
        <div
          className="oui-d-flex oui-flex-column oui-align-items-center"
          style={{ width: "100%" }}
        >
          <SamlErrorSvg
            title="Password Token Expired"
            width={120}
            height={120}
          />
          <h5 className="oui-mt-3">Authentication Failed</h5>
          <p className="oui-text-muted oui-text-center oui-mt-2 oui-mb-0">
            We apologize for the inconvenience caused and appreciate your
            patience.
          </p>
          <p className="oui-text-muted oui-text-center">
            For assistance in resolving this issue, contact{" "}
            <a
              href="https://support.druva.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support{" "}
            </a>
            with the following SAML response.
          </p>
        </div>

        <div style={{ width: "450px" }} className="oui-mt-2">
          <Input type="textarea" rows={6} readOnly value={samlAuthError} />
          <div className="oui-mt-3 oui-d-flex oui-justify-content-between">
            <div
              data-testid="back-btn"
              className="back-btn"
              onClick={() => {
                navigate("/auth/login");
              }}
            >
              <BackSvg width={12} />
              <a href="/">Back</a>
            </div>
            <CopyToClipboard
              onCopy={() => {
                dispatch(
                  showAlert({
                    message: "Error copied to clipboard.",
                    type: "success",
                  })
                );
              }}
              text={samlAuthError}
            >
              <Button size="sm" label="Copy To Clipboard" />
            </CopyToClipboard>
          </div>
        </div>
      </Page.Body>
    </Page.Main>
  );
};

export default SAMLError;
