import { API_STASHED_RESPONSE } from "consts";
import { useQuery } from "react-query";
import httpClient from "utils/http-client";

const getStachedResponse = ({ queryKey }) => {
  const formData = new FormData();
  formData.set("otp", queryKey[1]);
  return httpClient.post(API_STASHED_RESPONSE, formData);
};

export const useStashedResponse = (otp) =>
  useQuery(["get-stashed-response", otp], getStachedResponse, {
    enabled: Boolean(otp),
    retry: false,
  });
