import React from "react";
import { Heading } from "orion";

import accountLockedTemporarily from "assets/images/account-locked-temporarily.svg";

const AccountLocked = ({ unlockMoment }) => {
  return (
    <>
      <div className="oui-d-flex oui-flex-column oui-align-items-center oui-card-body oui-mx-3">
        <img
          src={accountLockedTemporarily}
          alt="locked icon"
          className="oui-mt-4 oui-mb-3"
          style={{ height: "64px" }}
          data-testid="login-acc-lock-ico"
        />
        <Heading
          type="5"
          text="Account Locked Temporarily"
          className="oui-font-weight-normal oui-mb-2 oui-text-center"
          dataTestId="login-acc-lock-lbl"
        />

        <div
          className="oui-text-secondary oui-text-center oui-mx-3 oui-mb-3"
          data-testid="login-acc-lock-msg-lbl"
        >
          Your account has been temporarily locked due to security reasons.
        </div>
        <div className="oui-small" data-testid="login-acc-lock-try-lbl">
          Try again after{" "}
          {unlockMoment.toLocaleTimeString("en-US", {
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </div>
    </>
  );
};

AccountLocked.defaultProps = {
  unlockMoment: new Date(Date.now() + 30 * 60 * 1000),
};

export default AccountLocked;
