import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import store from "utils/store";
import FullPageLoader from "shared/FullPageLoader";
import ToastRenderer from "shared/ReduxToast";

import App from "pages";
import * as serviceWorker from "./serviceWorker";

import "sanitize.css/sanitize.css";
import "orion/lib/styles/orion.css";
import { BASENAME } from "consts";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <BrowserRouter basename={BASENAME}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <App />
        <ToastRenderer />
        <FullPageLoader />
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
